* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
}

body {
  fontFamily: 'RobotoRegular';
  height: 100%;
  width: 100%;
  background-color: #ffff;
  -webkit-font-smoothing: antialiased;
  overflow-y: hidden;
}

/* this is a workaround to prevent the 'react-error-overlay' cover the document and disable clicking */
/* TODO check if it doesn't crash anything in a while */
body > iframe {
  pointer-events: none;
}

#root {
  height: 100%;
  width: 100%;
}


.toast-lg {
  font-size: 14px;
  min-width: 600px;
  left: -200px;
  word-break: break-word;
}

.bold-text {
  font-weight: bold;
}

@font-face {
  font-family: "InterRegular";
  font-style: normal;
  font-weight: normal;
  src: local("Inter-Regular"), url(../fonts/Inter/Inter-Regular.ttf) format("woff");
}

@font-face {
  font-family: "InterBold";
  font-style: normal;
  font-weight: normal;
  src: local("Inter-Bold"), url(../fonts/Inter/Inter-Bold.ttf) format("woff");
}

@font-face {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: normal;
  src: local("Inter-Regular"), url(../fonts/Roboto/Roboto-Regular.ttf) format("woff");
}

@font-face {
  font-family: "RobotoBold";
  font-style: normal;
  font-weight: normal;
  src: local("Inter-Bold"), url(../fonts/Roboto/Roboto-Bold.ttf) format("woff");
}



